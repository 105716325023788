import React from 'react'
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';

const Achievements = () => {
  return (
    <section className='w- full bg-white py-16'>
      <div className='w-[70%] ml-[15%]'>

        <Marquee pauseOnHover>
            <Link><img src='/img/home/TLGC2023.png' alt='award'/></Link>
            <Link><img src='/img/home/TDMA2024.png' alt='award'/></Link>
            <Link><img src='/img/home/TDMA.png' alt='award'/></Link>
            <Link><img src='/img/home/TLGC2024.png' alt='award'/></Link>
            <Link><img src='/img/home/TLGC.png' alt='award'/></Link>
            <Link><img src='/img/home/contactout.png' alt='award'/></Link>
        </Marquee>
      </div>

    </section>
  )
}

export default Achievements