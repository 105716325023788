import React from "react";
import { Link } from "react-router-dom";

const Prospect = () => {
  return (
    <section className="bg-gradient-to-b relative z-[2] from-[#330779] to-[#980071] text-white pb-20 mt-20">
      <div className="flex justify-center w-[80%] m-auto max-w-[1080px] -top-20 z-50 h-fit relative pt-[45%] sm:pt-[40%] md:pt-[35.2963%]">
        <iframe
          loading="lazy"
          title="EmailMiners.com - LinkedIn Lead Generation - B2B Lead Generation&nbsp;- Cold email outreach for Demo"
          src="https://www.youtube.com/embed/3if2ZTy0L1A?feature=oembed"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen=""
          name="fitvid0"
          className="w-full h-full absolute top-0 left-0 rounded-3xl"
        ></iframe>
      </div>
      <div className="flex flex-col md:flex-row gap-10 w-[70%] ml-[15%]">
        <div className="md:w-2/3">
          <img src="/img/Leadgen/aim.png" />
        </div>
        <div className="md:w-1/3">
          <h2 className="text-[#fca497] text-4xl">We Make it Easy to Connect With Ideal Prospects!</h2>
          <p className="mb-5">
          EmailMiners is a{" "}
            <span style={{ color: "#ffff99" }}>
              <strong>B2B lead generation agency</strong>
            </span>{" "}
            specializing in developing and generating more B2B leads for its clients.
          </p>
          
          <p className="mb-5">
            <span style={{ fontWeight: "400" }}>
            We understand how essential it is to start with high-accuracy data. There’s no point in wasting time on{" "}
              <span style={{ color: "#ffff99" }}>
                <strong>B2B leads</strong>
              </span>
              that are outdated or irrelevant to the goods and services you’ve got on offer.
            </span>
          </p>
          <div className="flex w-full justify-center mt-10 md:mt-0">
          <Link to={"/contact-us"} className="py-4 mb-5 px-7 text-white shadow-[0px_20px_30px_-10px] shadow-[#F7828E66] w-fit hover:tracking-[7px] transition-all duration-300 bg-gradient-to-r from-[#f92c8b] to-[#b02cd6] rounded-md tracking-[5px]">
            <b className="uppercase">Talk to specialist</b>
          </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url('/image.svg')",
          backgroundSize: "100% 480px",
        }}
        className="h-[480px] absolute bottom-0 z-[-1] scale-x-[1] left-0 right-0"
      ></div>
    </section>
  );
};

export default Prospect;
